;(function() {
  'use strict'

  angular.module('app.core').component('surveyExplorerFiltersDialog', {
    controller: Controller,
    templateUrl: 'survey-explorer-filters-dialog.html',
    bindings: {
      title: '<',
      datapack: '<',
      view: '<',
      canAddGlobal: '<',
      hiddenIds: '<',
      createFilter: '&',
      onDone: '&',
      onCancel: '&',
    },
  })

  /* @ngInject */
  function Controller() {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.search = search
    ctrl.all = all
    ctrl.none = none
    ctrl.toggle = toggle
    ctrl.isSelected = isSelected
    ctrl.add = add
    ctrl.create = create
    ctrl.cancel = cancel

    function onInit() {
      ctrl.query = ''
      ctrl.hiddenIds = ctrl.hiddenIds || []
      ctrl.selected = []
      ctrl.search()
    }

    function search(query) {
      if (!query) {
        ctrl.results = ctrl.datapack.filters
      } else {
        ctrl.results = ctrl.datapack.filters.filter(function(filter) {
          return filter.name.toLowerCase().includes(query.toLowerCase())
        })
      }
      ctrl.results = ctrl.results.filter(function(filter) {
        return !ctrl.hiddenIds.includes(filter.id)
      })
    }

    function all() {
      ctrl.selected = ctrl.results.slice()
    }

    function none() {
      ctrl.selected.length = 0
    }

    function toggle(filter) {
      var idx = ctrl.selected.indexOf(filter)
      if (idx === -1) {
        ctrl.selected.push(filter)
      } else {
        ctrl.selected.splice(idx, 1)
      }
    }

    function isSelected(filter) {
      return ctrl.selected.indexOf(filter) !== -1
    }

    function add(global) {
      ctrl.onDone({
        $result: {
          filters: ctrl.selected,
          global: global,
        },
      })
    }

    function create() {
      ctrl.createFilter().then(function(filter) {
        ctrl.selected.push(filter)
        ctrl.search()
      })
    }

    function cancel() {
      ctrl.onCancel()
    }
  }
})()
