;(function() {
  'use strict'

  Controller.$inject = ["api", "ResponseFilterRule", "Question", "LocationSet"];
  angular.module('app.core').component('responseFilterGroup', {
    controller: Controller,
    templateUrl: 'response-filter-group.html',
    bindings: {
      survey: '<',
      channels: '<',
      dimensions: '<',
      filter: '<',
      shareToken: '<',
      isReadOnly: '<readOnly',
      onChange: '&?',
    },
  })

  /* @ngInject */
  function Controller(api, ResponseFilterRule, Question, LocationSet) {
    var ctrl = this
    var emptyArray = []
    var questionsById = {}

    ctrl.opOptions = [
      { label: 'All of', value: 'AND' },
      { label: 'Any of', value: 'OR' },
    ]
    ctrl.statementQuantifierOptions = [
      { label: 'Any of', value: 'ANY' },
      { label: 'All of', value: 'ALL' },
      { label: 'None of', value: 'NONE' },
    ]
    ctrl.choiceQuantifierOptions = [
      { label: 'Any of', value: 'ANY' },
      { label: 'All of', value: 'ALL' },
      { label: 'None of', value: 'NONE' },
      { label: 'Exactly', value: 'EXACT' },
    ]
    ctrl.matrixChoiceQuantifierOptions = [
      { label: 'Any of', value: 'ANY' },
      { label: 'All of', value: 'ALL' },
      { label: 'None of', value: 'NONE' },
    ]
    ctrl.rankChoiceQuantifierOptions = [
      { label: 'Any of', value: 'ANY' },
      { label: 'All of', value: 'ALL' },
      { label: 'None of', value: 'NONE' },
    ]
    ctrl.textQuantifierOptions = [
      { label: 'Includes', value: 'INCLUDES' },
      { label: 'Excludes', value: 'EXCLUDES' },
      { label: 'Equals', value: 'EQUALS' },
    ]
    ctrl.numbersQuantifierOptions = [
      { label: 'Equals', value: 'EQUALS' },
      { label: 'Less than', value: 'LESS_THAN' },
      { label: 'Greater than', value: 'GREATER_THAN' },
      { label: 'Any of', value: 'ANY' },
      { label: 'None of', value: 'NONE' },
    ]
    ctrl.answerQuantifierOptions = [
      { label: 'Any of', value: 'ANY' },
      { label: 'All of', value: 'ALL' },
      { label: 'None of', value: 'NONE' },
    ]
    ctrl.dateQuantifierOptions = [
      { label: 'Min' /*'Greater than or equal to'*/, value: 'GTE' },
      { label: 'Max' /*'Less than or equal to'*/, value: 'LTE' },
      { label: 'Equals', value: 'EQUALS' },
    ]
    ctrl.dimensionValueQuantifierOptions = [
      { label: 'Any of', value: 'ANY' },
      { label: 'All of', value: 'ALL' },
      { label: 'None of', value: 'NONE' },
    ]
    ctrl.segmentQuantifierOptions = [
      { label: 'Any of', value: 'ANY' },
      { label: 'All of', value: 'ALL' },
      { label: 'None of', value: 'NONE' },
    ]
    ctrl.tagQuantifierOptions = [
      { label: 'Any of', value: 'ANY' },
      { label: 'All of', value: 'ALL' },
      { label: 'None of', value: 'NONE' },
    ]
    ctrl.kindOptions = [
      { label: 'Complete', value: 'COMPLETE' },
      { label: 'Exit', value: 'EXIT' },
      { label: 'Overquota', value: 'OVERQUOTA' },
    ]
    ctrl.addOptions = [
      { label: 'AND', value: 'AND' },
      { label: 'OR', value: 'OR' },
    ]

    ctrl.$onInit = onInit

    ctrl.getRuleType = getRuleType
    ctrl.setRuleType = setRuleType

    ctrl.areLocationsLoaded = areLocationsLoaded
    ctrl.getLocationOptions = getLocationOptions

    ctrl.getChoiceQuantifierOptions = getChoiceQuantifierOptions
    ctrl.getStatementOptions = getStatementOptions
    ctrl.getChoiceOptions = getChoiceOptions
    ctrl.getDimensionNameOptions = getDimensionNameOptions
    ctrl.getDimensionValueOptions = getDimensionValueOptions

    ctrl.add = add
    ctrl.removeRule = removeRule

    function onInit() {
      ctrl.typeOptions = [
        {
          label: 'Answered',
          value: ResponseFilterRule.Types.ANSWER,
          promoted: true,
        },
        { label: 'Seen', value: ResponseFilterRule.Types.SEEN, promoted: true },
        {
          label: 'Skipped',
          value: ResponseFilterRule.Types.SKIP,
          promoted: true,
        },
        {
          label: 'Channel',
          value: ResponseFilterRule.Types.CHANNEL,
          promoted: true,
        },
        { label: 'Date', value: ResponseFilterRule.Types.DATE, promoted: true },
        {
          label: 'Dimension',
          value: ResponseFilterRule.Types.DIMENSION,
          promoted: true,
        },
        {
          label: 'Segment',
          value: ResponseFilterRule.Types.SEGMENT,
          promoted: true,
        }, // TODO: apply UI
        { label: 'Tag', value: ResponseFilterRule.Types.TAG, promoted: true },
        {
          label: 'Response Type',
          value: ResponseFilterRule.Types.KIND,
          promoted: true,
        },
        {
          label: 'Response ID',
          value: ResponseFilterRule.Types.ID,
          promoted: true,
        },
      ]
      ctrl.filter.getQuestions().forEach(function(question) {
        // for now these 2 are not supported:
        if (question.type === Question.Types.PLACES_NEAR_ME) return
        if (question.type === Question.Types.IMAGE) return
        ctrl.typeOptions.push({
          label: question.getTitleLabel({ number: true }),
          value: 'Q_' + question.id,
        })
      })
      ctrl.answerQuestionOptions = ctrl.filter
        .getAnswerQuestions()
        .map(questionToOption)
      ctrl.survey.getQuestions().forEach(function(question) {
        questionsById[question.id] = question
      })
      ctrl.channelOptions = ctrl.channels.map(function(channel) {
        return {
          label: channel.name,
          value: channel.id,
        }
      })
      ctrl.segmentOptions = ctrl.survey.segments.map(function(segment) {
        return { label: segment, value: segment }
      })
      ctrl.tagOptions = []
      _.each(ctrl.survey.tagCounts, function(count, tag) {
        ctrl.tagOptions.push({ label: tag, value: tag })
      })
    }

    function getRuleType(rule) {
      if (rule.type === rule.Types.QUESTION) {
        return 'Q_' + rule.questionId
      }
      return rule.type
    }

    function setRuleType(rule, type) {
      if (type.startsWith('Q_')) {
        var questionId = type.split('Q_')[1]
        rule.type = rule.Types.QUESTION
        rule.questionId = questionId
      } else {
        rule.type = type
        rule.questionId = null
      }
    }

    function questionToOption(question) {
      return {
        label: question.getTitleLabel({ number: true }),
        value: question.id,
      }
    }

    function getChoiceQuantifierOptions(rule) {
      if (rule.isRankBased()) {
        return ctrl.rankChoiceQuantifierOptions
      }
      if (rule.isMatrixBased()) {
        return ctrl.matrixChoiceQuantifierOptions
      }
      return ctrl.choiceQuantifierOptions
    }

    var memoStatementOptions = {}
    function getStatementOptions(questionId) {
      var options = memoStatementOptions[questionId]
      if (!options) {
        var question = questionsById[questionId]
        if (!question) return emptyArray
        options = question.getVisibleStatements().map(function(item) {
          return {
            label: item.label,
            value: item.id,
          }
        })
        memoStatementOptions[questionId] = options
      }
      return options
    }

    var memoChoiceOptions = {}
    function getChoiceOptions(questionId) {
      var options = memoChoiceOptions[questionId]
      if (!options) {
        var question = questionsById[questionId]
        if (!question) return emptyArray
        options = question.getVisibleChoices().map(function(item) {
          return {
            label: item.label,
            value: item.id,
          }
        })
        memoChoiceOptions[questionId] = options
      }
      return options
    }

    var memoLocationData = {
      /* 
      [questionId]: { 
        loaded: Boolean, 
        typeOptions, 
        valueOptions  
      } 
    */
    }
    function areLocationsLoaded(questionId) {
      var data = memoLocationData[questionId]
      if (!data) return false
      return data.loaded
    }
    function getLocationData(questionId) {
      var data = memoLocationData[questionId]
      if (!data) {
        data = memoLocationData[questionId] = {
          loaded: false,
          options: null,
        }
        api.surveyReports
          .getTextAnswers({ id: questionId, token: ctrl.shareToken })
          .then(function(resp) {
            var locations = new LocationSet().fromTextAnswers(resp)
            var locationTypes = [
              LocationSet.Type.COUNTRY,
              LocationSet.Type.STATE,
              LocationSet.Type.CITY,
              LocationSet.Type.SUBURB,
              LocationSet.Type.POSTCODE,
              LocationSet.Type.PLACE_TYPE,
            ]
            data.options = []
            _.each(locationTypes, function(type) {
              var collection = locations.collections[type]
              if (!collection.count) return
              var options = collection.items.map(function(item) {
                return {
                  label: item.info.name + ': ' + item.name,
                  value: item.info.slot + ':' + item.nameLowerCase,
                }
              })
              data.options = data.options.concat(options)
            })
            data.loaded = true
          })
      }
      return data
    }
    function getLocationOptions(questionId) {
      return getLocationData(questionId).options
    }

    var memoDimensionNameOptions = null
    function getDimensionNameOptions() {
      var options = memoDimensionNameOptions
      if (!options) {
        options = []
        _.each(ctrl.dimensions.system, function(values, name) {
          options.push({ label: name, value: name })
        })
        _.each(ctrl.dimensions.report, function(values, name) {
          options.push({ label: name, value: name })
        })
        options = _.uniqBy(options, function(option) {
          return option.value
        })
        memoDimensionNameOptions = options
      }
      return options
    }

    var memoDimensionValueOptions = {}
    function getDimensionValueOptions(name) {
      if (!name) return emptyArray
      var options = memoDimensionValueOptions[name]
      if (!options) {
        options = []
        _.each(ctrl.dimensions.system[name], function(value) {
          if (name === 'page' && _.isArray(value)) {
            // PAGE dimension has array as value
            options.push({ label: value[1], value: value[0] })
          } else {
            options.push({ label: value, value: value })
          }
        })
        _.each(ctrl.dimensions.report[name], function(value) {
          if (name === 'page' && _.isArray(value)) {
            // PAGE dimension has array as value
            options.push({ label: value[1], value: value[0] })
          } else {
            options.push({ label: value, value: value })
          }
        })
        options = _.uniqBy(options, function(option) {
          return option.value
        })
        memoDimensionValueOptions[name] = options
      }
      return options
    }

    function add(group, op) {
      if (op === 'AND') {
        group.addRule()
      }
      if (op === 'OR') {
        ctrl.filter.addGroupAfter(group, true)
      }
    }

    function removeRule(group, rule) {
      ctrl.filter.removeRule(group, rule)
      if (!ctrl.filter.groups.length) {
        ctrl.filter.init()
      }
    }
  }
})()
