;(function() {
  'use strict'

  Controller.$inject = ["ResponseFilter", "filterSetService", "responseFilterService", "glToast"];
  angular.module('app.core').component('surveyExplorerFilterDialog', {
    controller: Controller,
    templateUrl: 'survey-explorer-filter-dialog.html',
    bindings: {
      survey: '<',
      channels: '<',
      dimensions: '<',
      filter: '<',
      onDone: '&',
      onCancel: '&',
    },
  })

  /* @ngInject */
  function Controller(
    ResponseFilter,
    filterSetService,
    responseFilterService,
    glToast
  ) {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.done = done
    ctrl.cancel = cancel

    function onInit() {
      ctrl.title = ctrl.filter ? 'Edit Filter' : 'Create Filter'
      if (ctrl.filter) {
        console.log('clone')
        ctrl.filter = ctrl.filter.clone()
      } else {
        console.log('new')
        ctrl.filter = new ResponseFilter(ctrl.survey).init()
      }
      // ctrl.channels = ctrl.report.channels
      // ctrl.dimensions = responseFilterService.extractDimensionsFromReport(
      //   ctrl.report
      // )
      // ctrl.isReadOnly =
      //   ctrl.filterSet.isReadOnly || !ctrl.report.options.canEditFilters
      // ctrl.visibilityOptions = [
      //   {
      //     label: 'Public (Shared Reports/Dynamic Reports)',
      //     value: false,
      //   },
      //   {
      //     label: 'Private (Glow Account Holders)',
      //     value: true,
      //   },
      // ]
      // ctrl.dialogTitle =
      //   (ctrl.isReadOnly ? 'View' : ctrl.filterSet.isNew() ? 'Add' : 'Edit') +
      //   ' Filter'
      // ctrl.tabs = [
      //   { label: 'Filter', value: 'filter' },
      //   { label: 'Charts', value: 'charts' },
      // ]
      // if (ctrl.filterSet.canEditVisibility()) {
      //   ctrl.tabs.push({
      //     label: 'Visibility',
      //     value: 'visibility',
      //   })
      // }
    }

    function setModified() {
      ctrl.filterSet.modified = true
    }

    function done() {
      ctrl.onDone({ $filter: ctrl.filter })
      // if (ctrl.filterSet.isSaving) return
      // if (!ctrl.filterSet.validate()) {
      //   glToast.show('Please complete the filter before saving.')
      //   return
      // }

      // filterSetService
      //   .save(ctrl.filterSet)
      //   .then(function() {
      //     ctrl.onSave()
      //   })
      //   .catch(function() {
      //     glToast.show('Error saving filter.')
      //   })
    }

    function cancel() {
      ctrl.onCancel()
    }
  }
})()
