/* globals google */
/* globals MarkerClusterer */
;(function() {
  'use strict'

  Controller.$inject = ["$scope", "$element", "$timeout", "mapService", "reportMapChartStyles"];
  var component = {
    controller: Controller,
    templateUrl: 'report-map-chart.template.html',
    bindings: {
      height: '=',
      showHeatmap: '=',
      question: '<',
      focus: '<',
      onMarkerClick: '&',
    },
  }

  angular
    .module('glow.reporting.charts')
    .component('glReportMapChart', component)

  /* @ngInject */
  function Controller(
    $scope,
    $element,
    $timeout,
    mapService,
    reportMapChartStyles
  ) {
    var ctrl = this

    $element.addClass('reportMapChart')

    // map option defaults
    ctrl.options = {
      center: { lat: -34.397, lng: 150.644 }, // arbitrary, doesnt matter
      zoom: 8,
      zoomControl: false,
      streetViewControl: false,
      mapTypeControl: false,
      fullscreenControl: false,
      styles: reportMapChartStyles,
    }

    ctrl.$onInit = onInit
    ctrl.$onChanges = onChanges

    function onInit() {
      mapService.load().then(function() {
        // initialise a heatmap with no data
        ctrl.heatmap = new google.maps.visualization.HeatmapLayer()
        ctrl.map = makeMap(ctrl.options)
        update()
      })
    }

    function onChanges(changes) {
      if (!ctrl.map) return
      if (changes.question) {
        update()
      }
      if (ctrl.map) {
        focusLocation(
          ctrl.map,
          ctrl.question.questionResults.locations,
          ctrl.focus
        )
      }
    }

    function makeBaseBounds() {
      var bounds = new google.maps.LatLngBounds()
      // our base bounds start with Australia (TODO: is this necessary?)
      bounds.extend(new google.maps.LatLng(-11.885944, 112.773279)) // top left of australia
      bounds.extend(new google.maps.LatLng(-44.657796, 154.162186)) // bottom right of australia
      return bounds
    }

    function makeMap(options) {
      // get the element we will draw the map to
      var elem = $element[0].querySelector('.reportMapChart-map')
      // create the map
      var map = new google.maps.Map(elem, options)
      // change the background (visible when tiles aren't loaded) from gray to white
      angular.element(elem).children()[0].style.backgroundColor = '#fff'
      // map.addListener('zoom_changed', function () { console.log(map.getZoom()); }); // HELPER: get zoom level
      return map
    }

    function update() {
      if (ctrl.showHeatmap) {
        updateHeatmap(
          ctrl.heatmap,
          ctrl.question.questionResults.locations,
          ctrl.map
        )
      } else {
        updateMarkers(ctrl.question.questionResults.locations, ctrl.map)
      }
    }

    function updateHeatmap(heatmap, locations, map) {
      // clear any existing heatmap
      heatmap.setMap(null)

      // get a fresh bounds
      var bounds = makeBaseBounds()

      // convert our location-set latlngs into google map LatLng classes
      var latlngs = _.map(locations.getByType(locations.Type.LATLNG), function(
        item
      ) {
        var ll = item.name.split(',')
        var latlng = new google.maps.LatLng(ll[0], ll[1])

        // also extend our bounds incase we have locations outside of Australia
        bounds.extend(latlng)

        return latlng
      })

      // apply the heatmap to our map
      heatmap.setData(latlngs)
      heatmap.setMap(map)

      // zoom to fit all points
      map.fitBounds(bounds)

      // dont zoom in too far
      var zoom = _.clamp(map.getZoom(), 0, 10)
      map.setZoom(zoom)
    }

    function updateMarkers(locations, map) {
      // create a marker clusterer if we haven't already
      if (!ctrl.markerClusterer) {
        ctrl.markerClusterer = new MarkerClusterer(map, null, {
          imagePath:
            'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
        })
      }

      // clear any existing markers
      ctrl.markerClusterer.clearMarkers()
      // ctrl.markerClusterer.setMap(null);

      // get a fresh bounds
      var bounds = makeBaseBounds()

      // convert our location-set latlngs into google map Marker classes
      var markers = _.map(locations.locations, function(location) {
        var ll = location.latlng.split(',')
        var latlng = new google.maps.LatLng(ll[0], ll[1])

        // also extend our bounds incase we have locations outside of Australia
        bounds.extend(latlng)
        // return a marker
        var marker = new google.maps.Marker({
          position: latlng,
          label: {
            text: location.label,
            color: '#fff',
          },
          icon: {
            path: google.maps.SymbolPath.CIRCLE,
            fillColor: location.color,
            fillOpacity: 1.0,
            scale: 12,
            strokeWeight: 0,
            strokeOpacity: 0.0,
          },
        })
        marker.addListener('click', function() {
          ctrl.onMarkerClick({ $value: location.value })
        })
        return marker
      })

      // apply the markers
      ctrl.markerClusterer.addMarkers(markers)

      // zoom to fit all points
      map.fitBounds(bounds)
    }

    function focusLocation(map, locations, filter) {
      var location = _.find(locations.locations, filter)
      if (!filter || !location) {
        restoreState(map)
      } else {
        rememberState(map)
        var ll = location.latlng.split(',')
        var latlng = new google.maps.LatLng(ll[0], ll[1])
        map.panTo(latlng)
      }
    }

    function rememberState(map) {
      ctrl.state = map.getBounds()
    }

    function restoreState(map) {
      if (ctrl.state) {
        map.panToBounds(ctrl.state)
      }
    }
  }
})()
