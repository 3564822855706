;(function() {
  'use strict'

  Controller.$inject = ["glUtils"];
  angular.module('glow.reporting').component('surveyExplorerSettingsDialog', {
    controller: Controller,
    templateUrl: 'survey-explorer-settings-dialog.html',
    bindings: {
      datapack: '<',
      onApply: '&',
      onCancel: '&',
    },
  })

  /* @ngInject */
  function Controller(glUtils) {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.apply = apply
    ctrl.cancel = cancel

    function onInit() {
      // ...
    }

    function apply() {
      ctrl.onApply()
    }

    function cancel() {
      ctrl.onCancel()
    }
  }
})()
