;(function() {
  'use strict'

  Controller.$inject = ["$rootScope", "$scope", "$log", "$timeout", "$document", "$window", "$state", "app", "StateFactory", "ReportLink", "LoopTracker", "Survey", "surveyReport", "filterSetService", "filterSetMongoService", "glDialog", "glAnalytics", "glTips", "glUtils", "reportLinkService", "reportQuestionExporter", "reportPPTXExporter", "accountService", "featureService", "userService", "reportSettingsService", "responseTypeService", "uacService", "subscriberService"];
  angular.module('glow.reporting').component('surveyReport', Component())

  function Component() {
    return {
      controller: Controller,
      templateUrl: 'survey-report.html',
      bindings: {
        surveyId: '<',
        filters: '<',
        filtersOperator: '<',
        onFiltersChange: '&',
        onResponseTypeChange: '&',
        options: '<',
        onLoadError: '&',
        onLoadSuccess: '&',
        headerSize: '<',
      },
    }
  }

  /* @ngInject */
  function Controller(
    $rootScope,
    $scope,
    $log,
    $timeout,
    $document,
    $window,
    $state,
    app,
    StateFactory,
    ReportLink,
    LoopTracker,
    Survey,
    surveyReport,
    filterSetService,
    filterSetMongoService,
    glDialog,
    glAnalytics,
    glTips,
    glUtils,
    reportLinkService,
    reportQuestionExporter,
    reportPPTXExporter,
    accountService,
    featureService,
    userService,
    reportSettingsService,
    responseTypeService,
    uacService,
    subscriberService
  ) {
    var ctrl = this
    $log = $log.create('surveyReport')

    var loadReportRequestNum = 0
    ctrl.survey = {}

    ctrl.exportFormats = [
      { label: 'Responses (csv)', type: 'qcsv' },
      { label: 'Responses (xlsx)', type: 'qxlsx' },
      { label: 'Responses (SPSS Compatible xlsx)', type: 'qspss' },
      { label: 'Excel Workbook (xlsx)', type: 'xlsx' },
      { label: 'PowerPoint (pptx)', type: 'pptx' },
    ]

    ctrl.setTile = setTile
    ctrl.checkExportPermission = checkExportPermission
    ctrl.showShareManager = showShareManager
    ctrl.showSettingsManager = showSettingsManager
    ctrl.exportResponses = exportResponses
    ctrl.toggleSidenav = toggleSidenav
    ctrl.onFilterSetChange = _.debounce(onFilterSetChange, 700)
    ctrl.getVisibleTiles = getVisibleTiles
    ctrl.isTileVisible = isTileVisible
    ctrl.compare = compare
    ctrl.closeCompare = closeCompare
    ctrl.zoom = zoom
    ctrl.closeZoom = closeZoom
    ctrl.responseTypeOptions = responseTypeService.selectOptions
    ctrl.getSelectedResponseType = responseTypeService.getSelected
    ctrl.setSelectedResponseType = setSelectedResponseType
    ctrl.switchToBeta = switchToBeta

    $scope.$on(
      'survey-interaction:response-deleted',
      refreshReport.bind(null, false)
    )
    $scope.$on('mods:changed', refreshReport.bind(null, true))
    $scope.$on('report-settings:updated', refreshReport.bind(null, true))
    $scope.$on('edit-question-theme', onEditQuestionTheme)

    ctrl.$onInit = onInit
    ctrl.$onDestroy = onDestroy

    function onInit() {
      ctrl.subscriber = subscriberService.getSubscriber()
      ctrl.isSSR = userService.isSSR()
      ctrl.options = ctrl.options || {}

      $document.bind('keydown', onKeyDown)

      ctrl.loopTracker = new LoopTracker(function() {
        console.log('refreshing report (loop variables modified')
        refreshReport(true)
      })

      if (ctrl.options.responseType !== undefined) {
        responseTypeService.setSelected(ctrl.options.responseType)
      }

      ctrl.state = new StateFactory([
        'loading',
        'ready',
        'noResponses',
        'error',
      ])
      ctrl.state.loading()

      loadSurvey()
        .then(loadFilterSetGroup)
        .then(function(filterSetGroup) {
          ctrl.filterSetGroup = filterSetGroup
          if (ctrl.filters) {
            ctrl.filterSetGroup.selectByFilterSetIds(ctrl.filters.split(','))
          }
          ctrl.filterSetGroup.refreshSelected()
          if (ctrl.filtersOperator) {
            ctrl.filterSetGroup.setOperator(ctrl.filtersOperator)
          }
          ctrl.hiddenCharts = ctrl.filterSetGroup.getHiddenCharts()
          ctrl.currentQuery = JSON.stringify(
            filterSetMongoService.parse(
              ctrl.filterSetGroup.getSelected(),
              ctrl.filterSetGroup.getOperator()
            )
          )
          initReport()
        })
        .catch(function(error) {
          ctrl.state.error()
          $log.error('could not load filters')
          ctrl.onLoadError({
            $error: error,
            $status: error.status,
            $message: error.message,
          })
        })
    }

    function loadSurvey() {
      // NOTE: this method will cache the survey so that report can use the cache
      // instead of re-fetching the survey except when forced
      return surveyReport.fetchSurvey(ctrl.surveyId).then(function(data) {
        return new Survey().deserialize(data)
      })
    }

    function loadFilterSetGroup(survey) {
      return filterSetService.loadGroup(
        survey,
        ctrl.options.accessType,
        ctrl.options.shareToken
      )
    }

    function initReport() {
      loadReport()
        .then(function(survey) {
          if (ctrl.options.setTitle !== false) {
            app.setTitle(survey.title)
          }
          ctrl.mode = 'tile'
          ctrl.tile = getVisibleTiles()[0]
          var view = $state.params.view
          if (view === 'crosstabs') {
            ctrl.mode = 'crosstabs'
            ctrl.tile = null
          } else if (view && view.startsWith('q')) {
            var number = parseInt(view.slice(1))
            var tile = getVisibleTiles().find(function(tile) {
              return tile.type === 'question' && tile.question.number === number
            })
            if (tile) {
              ctrl.mode = 'tile'
              ctrl.tile = tile
            }
          } else if (view && view.startsWith('s')) {
            var number = parseInt(view.slice(1))
            var tile = getVisibleTiles().find(function(tile) {
              return tile.type === 'section' && tile.section.number === number
            })
            if (tile) {
              ctrl.mode = 'tile'
              ctrl.tile = tile
            }
          }
          ctrl.state[survey.responseCount < 1 ? 'noResponses' : 'ready']()
          ctrl.onLoadSuccess({
            $title: survey.title,
            $tags: {
              title: survey.title,
              description: 'Find insights faster on Glow', // survey.description,
              image: survey.imageUrl,
            },
            $subscriberId: survey.ownerId,
          })
          $timeout(glTips.ready, 500)
          trackRender('init')
        })
        .catch(function(error) {
          console.error(error)
          ctrl.state.error()
          $log.error('could not load survey')
          ctrl.onLoadError({
            $error: error,
            $status: error.status,
            $message: error.message,
          })
        })
    }

    function loadReport(force) {
      $rootScope.$broadcast('survey-reporting:load-start')
      var networkStart = performance.now()
      var thisRequest = ++loadReportRequestNum
      return surveyReport
        .get(
          ctrl.surveyId,
          ctrl.options,
          ctrl.filterSetGroup.getSelected(),
          ctrl.filterSetGroup.getOperator(),
          force,
          ctrl.getSelectedResponseType(),
          ctrl.loopTracker
        )
        .then(function(survey) {
          // ignore the survey report if there's a new request
          if (thisRequest !== loadReportRequestNum) {
            return
          }
          console.info('network:', performance.now() - networkStart)
          ctrl.renderStart = performance.now() // quazi-rendering timer
          ctrl.survey = survey
          if (ctrl.zoomQuestion || $state.params.zoom) {
            // if the zoom view is open we need to update to the new
            // zoom question reference
            ctrl.zoomQuestion = survey.questions.find(function(question) {
              return (
                question.id ===
                ((ctrl.zoomQuestion && ctrl.zoomQuestion.id) ||
                  $state.params.zoom)
              )
            })
          }
          $rootScope.$broadcast('survey-reporting:load-end')
          return survey
        })
    }

    function getVisibleTiles() {
      if (!ctrl.survey.tiles) return []
      return ctrl.survey.tiles.filter(isTileVisible)
    }

    function setTile(tile) {
      if (tile) {
        ctrl.mode = 'tile'
        ctrl.tile = null
        $timeout(() => {
          ctrl.mode = 'tile'
          ctrl.tile = tile
          glUtils.changeParams({
            view: tile.question
              ? 'q' + tile.question.number
              : 's' + tile.section.number,
          })
        }, 1)
      } else {
        ctrl.mode = 'crosstabs'
        ctrl.tile = null
        glUtils.changeParams({ view: 'crosstabs' })
      }
    }

    function isTileVisible(tile) {
      if (tile.type === 'section') {
        var isHidden = tile.section.isHidden
        return !isHidden
      }
      if (tile.type === 'question') {
        var isHiddenQuestion = tile.question.isHidden
        var isHiddenShown = ctrl.survey.showHiddenQuestions
        var isHiddenChart = _.includes(ctrl.hiddenCharts, tile.question.id)
        if (isHiddenChart) return false
        return !isHiddenQuestion || isHiddenShown
      }
    }

    // TODO: directly call refresh?
    function onFilterSetChange(skipUpdateURLParams) {
      var query = JSON.stringify(
        filterSetMongoService.parse(
          ctrl.filterSetGroup.getSelected(),
          ctrl.filterSetGroup.getOperator()
        )
      )
      var queryDidChange = query !== ctrl.currentQuery
      console.log('queryDidChange', queryDidChange)
      if (queryDidChange) {
        ctrl.currentQuery = query
        refreshReport()
      }
      ctrl.hiddenCharts = ctrl.filterSetGroup.getHiddenCharts()

      if (!skipUpdateURLParams) {
        var filterSetIds = _.map(ctrl.filterSetGroup.getSelected(), 'id')
        var operator =
          filterSetIds.length > 1 ? ctrl.filterSetGroup.getOperator() : null
        filterSetIds = filterSetIds.length ? filterSetIds.join(',') : null
        ctrl.onFiltersChange({
          $filterSetIds: filterSetIds,
          $operator: operator,
        })
      }
    }

    function refreshReport(force) {
      ctrl.isRefreshing = true
      loadReport(force).then(function(survey) {
        // if survey is empty, that means there's a new request so we keep it loading
        if (!survey) {
          return
        }
        ctrl.isRefreshing = false
        trackRender('filter-change')
        if (ctrl.mode === 'tile') {
          var tile = ctrl.survey.tiles.find(tile => tile.id === ctrl.tile.id)
          setTile(tile)
        }
      })
    }

    function trackRender(context) {
      setTimeout(function() {
        var ms = performance.now() - ctrl.renderStart
        glAnalytics.track('SurveyReport', 'Render', context, ms)
        console.info('render:', ms) // $log ignores info in production :/
      })
    }

    function checkExportPermission() {
      if (!ctrl.options.canExportReport) {
        uacService.showAlertDialog('export analysis report')
        return false
      }
    }

    function showShareManager() {
      if (!ctrl.options.canShareReport) {
        return uacService.showAlertDialog('share analysis report')
      }
      reportLinkService.showListDialog(ctrl.survey.id)
    }

    function showSettingsManager() {
      if (!ctrl.options.canEditReportSettings) {
        return uacService.showAlertDialog('edit analysis report settings')
      }
      reportSettingsService.showDialog(ctrl.survey.id)
    }

    function exportResponses(type) {
      accountService
        .promptUpgrade({
          context: 'export-responses',
          bypassCondition:
            featureService.canExportResponses() ||
            (ctrl.options.canExportReport && ctrl.options.shareToken),
        })
        .then(function() {
          var responseType = ctrl.getSelectedResponseType()
          if (['qcsv', 'qxlsx', 'qspss'].includes(type)) {
            // this export is temporary and should not be available
            // with the new analysis since it focuses on outputting the visualisations
            var questionIds = []
            if (ctrl.options.visibilityMode !== ReportLink.Modes.ALL) {
              questionIds = ctrl.survey.questions
                .filter(function(question) {
                  return reportLinkService.isViewVisible(
                    question.id,
                    ctrl.options.visibilityMode,
                    ctrl.options.visibilityViewIds
                  )
                })
                .map(function(question) {
                  return question.id
                })
            }
            var options = {
              surveyId: ctrl.survey.id,
              responseType: responseType,
              shareToken: ctrl.options.shareToken,
              questionIds: questionIds,
              labelsToRewrite: ctrl.survey.labelsToRewrite,
              onlyLoopVariableIds: ctrl.options.onlyLoopVariableIds,
              filterSets: ctrl.filterSetGroup.getSelected(),
              filterSetsOperator: ctrl.filterSetGroup.getOperator(),
            }
            if (type === 'qxlsx') {
              options.exportFormat = 'xlsx'
            }
            if (type === 'qspss') {
              options.exportFormat = 'spss'
            }
            // IMPORTANT: SPSS doesn't support durations so we should always set it to false
            options.withDuration =
              options.exportFormat !== 'spss' &&
              ctrl.isSSR &&
              ctrl.subscriber.data.withDurationExport
            surveyReport.createExport(options)
          }
          if (type === 'xlsx') {
            reportQuestionExporter.exportWorkbook(
              ctrl.survey,
              ctrl.filterSetGroup.getSelected(),
              ctrl.filterSetGroup.getOperator(),
              responseType
            )
          }
          if (type === 'pptx') {
            reportPPTXExporter.export(ctrl.survey)
          }
        })
    }

    function toggleSidenav() {
      $rootScope.$emit('sidenav:toggle')
    }

    function compare(question) {
      ctrl.compareQuestionId = question.id
      ctrl.compareScroll = $window.scrollY
      $window.scrollTo({ top: 0 })
    }

    function closeCompare() {
      ctrl.compareQuestionId = null
      $timeout(function() {
        $window.scrollTo({ top: ctrl.compareScroll })
      }, 500)
    }

    function zoom(question) {
      ctrl.zoomQuestion = question
      ctrl.zoomQuestionScroll = $window.scrollY
      $window.scrollTo({ top: 0 })
      glUtils.changeParams({ zoom: ctrl.zoomQuestion.id })
    }

    function closeZoom() {
      ctrl.zoomQuestion = null
      $timeout(function() {
        $window.scrollTo({ top: ctrl.zoomQuestionScroll })
      }, 500)
      glUtils.changeParams({ zoom: null })
    }

    function setSelectedResponseType(value) {
      if (ctrl.getSelectedResponseType() === value) return
      responseTypeService.setSelected(value)
      refreshReport()

      // Add/change the selected responseType on URL params
      // if selected response type is All, the value is null
      // and won't be added to the URL params so we replace it wih 'all' instead
      // transform the response type to lower case for better URL
      var responseType = value ? value.toLowerCase() : 'all'
      ctrl.onResponseTypeChange({ $responseType: responseType })
    }

    function onEditQuestionTheme(e, question) {
      // prettier-ignore
      var template = [
        '<gl-dialog class="theme-question-dialog__dialog">',
          '<theme-question-dialog',
            'survey-id="surveyId"',
            'question-id="questionId"',
            'on-complete="dialog.close()"',
            'on-close="dialog.cancel()"',
          '/>',
        '</gl-dialog>'
      ]
      glDialog
        .show({
          template: template.join(' '),
          escapeToClose: true,
          clickOutsideToClose: true,
          locals: {
            surveyId: ctrl.survey.id,
            questionId: question.id,
          },
        })
        .then(function() {
          refreshReport(true)
        })
    }

    function onKeyDown(e) {
      function scrollToTile(tile) {
        var sidebar = document.querySelector('.xsurvey-report__left-main')
        var target = document.querySelector(`[tile=t${tile.id}]`)
        sidebar.scroll({
          top: target.offsetTop - sidebar.offsetHeight / 2,
          behavior: 'smooth',
        })
      }
      if (ctrl.mode !== 'tile') return
      if (!ctrl.tile) return
      if (!ctrl.survey.tiles) return
      var tiles = getVisibleTiles()
      var idx = tiles.indexOf(ctrl.tile)
      if (e.code === 'ArrowDown') {
        e.preventDefault()
        $scope.$applyAsync(function() {
          var nextTile = tiles[idx + 1]
          if (nextTile) {
            setTile(nextTile)
            scrollToTile(nextTile)
          }
        })
      }
      if (e.code === 'ArrowUp') {
        e.preventDefault()
        $scope.$applyAsync(function() {
          var nextTile = tiles[idx - 1]
          if (nextTile) {
            setTile(nextTile)
            scrollToTile(nextTile)
          }
        })
      }
    }

    function onDestroy() {
      // reset the selected response type back to default on destroyed
      responseTypeService.setSelected(responseTypeService.Types.COMPLETE)
      glUtils.changeParams({ zoom: null })
      $document.unbind('keydown', onKeyDown)
    }

    function switchToBeta() {
      $rootScope.$emit('toggleBeta', true)
    }
  }
})()
