;(function() {
  'use strict'

  Controller.$inject = ["$timeout", "Channel", "configService", "channelService", "clipboardService", "glUtils", "glToast", "glDialog"];
  angular.module('app.core').component('channelListItemDashboard', {
    controller: Controller,
    templateUrl: 'channel-list-item-dashboard.html',
    bindings: {
      channel: '<',
      survey: '<',
    },
  })

  /* @ngInject */
  function Controller(
    $timeout,
    Channel,
    configService,
    channelService,
    clipboardService,
    glUtils,
    glToast,
    glDialog
  ) {
    var ctrl = this
    var Types = Channel.Type

    ctrl.zoneLink = configService.getZoneUrl()
    ctrl.types = channelService.getTypes()
    ctrl.copyToClipboard = clipboardService.copy
    ctrl.copyChannelLink = copyChannelLink
    ctrl.copyExternalPanelLink = copyExternalPanelLink
    ctrl.hasBasicStats = hasBasicStats
    ctrl.hasAdvancedStats = hasAdvancedStats
    ctrl.generatePanelLink = generatePanelLink

    ctrl.$onChanges = onChanges

    function onChanges() {
      ctrl.type = _.find(ctrl.types, { value: ctrl.channel.type })

      ctrl.productsLabel =
        ctrl.channel.products.length +
        glUtils.numberSwitch(
          ctrl.channel.products.length,
          ' product',
          ' products'
        )
      ctrl.placesLabel =
        ctrl.channel.places.length +
        glUtils.numberSwitch(ctrl.channel.places.length, ' place', ' places')

      ctrl.responsesLabel =
        ctrl.channel.responseCount +
        glUtils.numberSwitch(
          ctrl.channel.responseCount,
          ' response',
          ' responses'
        )

      if (ctrl.channel.exitCount || ctrl.survey.hasExitLogic()) {
        ctrl.exitsLabel =
          ctrl.channel.exitCount +
          glUtils.numberSwitch(ctrl.channel.exitCount, ' exit', ' exits')
      }

      if (ctrl.channel.quotaCount > 0) {
        ctrl.quotaHitsLabel = ctrl.channel.quotaCount + ' over-quota'
      }

      var reward = ctrl.channel.reward
      if (reward) {
        ctrl.spendPercentage =
          Math.round(
            (reward.currentSpend.amount / reward.spendTarget.amount) * 100
          ) + '%'
        ctrl.spendProgress =
          reward.currentSpend.format() + ' / ' + reward.spendTarget.format()
      }
    }

    function copyChannelLink(channel) {
      if (!channel.enabled) {
        return glDialog
          .confirm(
            'Copy disabled link',
            "This link is currently disabled and won't become active until the channel is live."
          )
          .then(function() {
            clipboardService.copy(channel.link.shortUrl)
          })
      }
      clipboardService.copy(channel.link.shortUrl)
    }

    function copyExternalPanelLink(link) {
      if (!ctrl.channel.enabled) {
        return glDialog
          .confirm(
            'Copy disabled link',
            "This link is currently disabled and won't become active until you start the channel."
          )
          .then(function() {
            clipboardService.copy(link)
          })
      }
      clipboardService.copy(link)
    }

    function hasBasicStats() {
      return _.includes(
        [Types.CUSTOM, Types.PRODUCTS, Types.PLACES, Types.KIOSK],
        ctrl.channel.type
      )
    }

    function hasAdvancedStats() {
      return _.includes([Types.PANEL, Types.EXTERNAL_PANEL], ctrl.channel.type)
    }

    // this is a developer easter egg to help with testing panel channels in staging.
    // click the stats-bar quickly 4 times and a respondent link for hte panel is generated
    // and added to your clipboard
    var genCount = 0
    var genTimer
    function generatePanelLink() {
      genCount++
      if (genCount === 4) {
        var link = ctrl.channel.panel.getFakeRespondentLink()
        copyToClipboard(link)
        glToast.show('Copied to clipboard!')
        genCount = 0
      } else {
        $timeout.cancel(genTimer)
        genTimer = $timeout(function() {
          genCount = 0
        }, 700)
      }
    }
  }
})()
