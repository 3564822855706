;(function() {
  'use strict'

  Controller.$inject = ["$scope", "glUtils"];
  angular.module('app.core').component('surveyExplorerSelect', {
    controller: Controller,
    templateUrl: 'survey-explorer-select.html',
    bindings: {
      kind: '<',
      options: '<',
      value: '<',
      onChange: '&',
      canConfigure: '<',
      onConfigure: '&',
    },
  })

  /* @ngInject */
  function Controller($scope, glUtils) {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.getOption = getOption
    ctrl.toggle = toggle
    ctrl.select = select
    ctrl.configure = configure
    ctrl.$onDestroy = onDestroy

    function onInit() {
      ctrl.id = glUtils.uuid()
    }

    function getOption() {
      return _.find(ctrl.options, { id: ctrl.value })
    }

    function toggle(event) {
      if (event) {
        event.selectId = ctrl.id
      }
      if (ctrl.open) {
        close()
      } else {
        open()
      }
    }

    function open() {
      if (ctrl.open) return
      ctrl.open = true
      document.addEventListener('click', onDocumentClick)
    }

    function close() {
      if (!ctrl.open) return
      ctrl.open = false
      document.removeEventListener('click', onDocumentClick)
    }

    function onDocumentClick(event) {
      if (event.selectId === ctrl.id) return
      $scope.$applyAsync(function() {
        close()
      })
    }

    function select(option) {
      ctrl.onChange({ $value: option.id })
      close()
    }

    function configure(event) {
      event.preventDefault()
      ctrl.onConfigure({})
    }

    function onDestroy() {
      close()
    }
  }
})()
