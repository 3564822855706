;(function() {
  'use strict'

  Controller.$inject = ["subscriberService"];
  angular.module('app.core').component('surveyExplorerThemeDialog', {
    controller: Controller,
    templateUrl: 'survey-explorer-theme-dialog.html',
    bindings: {
      datapack: '<',
      view: '<',
      onDone: '&',
      onCancel: '&',
    },
  })

  /* @ngInject */
  function Controller(subscriberService) {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.done = done
    ctrl.cancel = cancel

    function onInit() {
      ctrl.subscriber = subscriberService.getSubscriber()
      ctrl.global = false
      console.log('theme:sub', ctrl.subscriber.themeContext)
      console.log('theme:dp', ctrl.datapack.theme)
      console.log('theme:view', ctrl.view.theme)
    }

    function done() {
      ctrl.onDone()
    }

    function cancel() {
      ctrl.onCancel()
    }
  }
})()
